import { FC, PropsWithChildren, useContext, useEffect } from 'react';
import UserContext from '../../../core/context/user.context';
import mixpanel from 'mixpanel-browser';
import TenantFilterParamsContextProvider from '../../../core/provider/TenantFilterParamsContextProvider';
import ApplicationListContextProvider from '../../../core/provider/ApplicationListContext.Provider';
const AppDataProvider: FC<PropsWithChildren> = ({ children }) => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: process.env.REACT_APP_ENVIRONMENT !== 'production',
    track_pageview: 'full-url',
    //@ts-ignore
    record_sessions_percent: 100, //records 1% of all sessions
    record_mask_text_selector: '',
  });
  const { user } = useContext(UserContext);

  useEffect(() => {
    mixpanel.opt_in_tracking();
  }, [window, document]);

  useEffect(() => {
    if (user?.uid) {
      mixpanel.identify(user.uid);
      // @ts-ignore
      mixpanel.start_session_recording();

      const updateLastSeen = () => {
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);

        mixpanel.people.set({
          $last_seen: yesterday.toISOString(),
        });
      };
      updateLastSeen();
      const intervalId = setInterval(updateLastSeen, 300000);
      return () => clearInterval(intervalId);
    }
  }, [user]);

  return (
    <TenantFilterParamsContextProvider>
      <ApplicationListContextProvider>{children}</ApplicationListContextProvider>
    </TenantFilterParamsContextProvider>
  );
};

export default AppDataProvider;
