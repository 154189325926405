import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from 'component/atoms/formElement/InputOptionList';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions, PETS_ALLOWED_SELECT_OPTIONS } from 'core/const/select-options';
import { FormContext } from 'core/context/form.context';
import { FC, useEffect, useState } from 'react';
import { IApartmentDesiredTenant } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { wohnsinnServices } from 'App';
import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import useFormErrorHook from 'core/hook/use-form-error.hook';
import { ICreateApartmentTunnel } from 'view/landlord/apartment/ApartmentCreateView';
import useApartment from 'core/hook/apartment.hook';
import { useNavigate } from 'react-router-dom';
import FormNavigation from 'component/molecules/FormNavigation';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import FormHeader from 'component/atoms/FormHeader';
import InputNumber from 'component/atoms/formElement/InputNumber';
import { APARTMENT_DESIRED_TENANT_SCHEMA } from 'core/schemas/apartment';
import { ROUTES } from 'core/const/routes';
import BackButton from 'component/atoms/Buttons/BackButton';
import useWindowSize from 'core/hook/windowsize.hook';
import TextEditor, { TEXT_EDITOR_SIZE } from 'component/atoms/formElement/TextEditor';

const ApartmentDesiredTenantForm: FC<ICreateApartmentTunnel> = ({ isTunnelView }) => {
  const { apartmentService } = wohnsinnServices;
  const queryClient = useQueryClient();
  const { apartment } = useApartment();
  const navigate = useNavigate();
  const { isSmallerMd } = useWindowSize();

  const { handleError } = useFormErrorHook('ApartmentDesiredTenantForm');
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { control, handleSubmit, watch, reset } = useForm<IApartmentDesiredTenant>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(APARTMENT_DESIRED_TENANT_SCHEMA)),
    defaultValues: apartment?.desiredTenant ?? null,
  });

  useEffect(() => {
    if (!!apartment?.desiredTenant) {
      reset(apartment.desiredTenant);
    }
  }, [apartment]);

  const onError = (err: Partial<FieldErrorsImpl<IApartmentDesiredTenant>>): void => {
    console.error(err);
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
  };

  const saveDataToDB = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      await apartmentService.updateApartment(
        {
          data: { ...apartment, desiredTenant: watch() },
          creatorId: apartment.creatorId,
          apartmentId: apartment.id,
        },
        false
      );
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
      if (isTunnelView) {
        navigate(r(ROUTES.landlordRoutes.apartment.create.contactPerson.path).replace(':apartmentId', apartment?.id));
      } else {
        navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment?.id));
      }
    } catch (e) {
      handleError(e);
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    }
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form
        onSubmit={handleSubmit(saveDataToDB, onError)}
        noValidate
        autoComplete="off"
        id={'apartmentDesiredTenantForm'}
      >
        <Grid container className={'form-with-navigation'}>
          <Grid item xs={12} md={6}>
            {!isTunnelView && !isSmallerMd && (
              <div style={{ paddingBottom: '1rem' }}>
                <BackButton />
              </div>
            )}
            <FormHeader
              title={t('apartmentDesiredTenantForm.title')}
              subtitle={t('apartmentDesiredTenantForm.subTitle')}
            ></FormHeader>
            <InputNumber
              step={1}
              name={'numberOfPeopleMovingIn'}
              label={t('numberOfPeopleMovingIn.label')}
              placeHolder={t('doesntMatter')}
              max={10}
            />
            <InputOptionList
              mode={'radio'}
              name={'isSharedUsagePossible'}
              options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
              columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
              label={t('apartment.desiredTenant.isSharedUsageAllowed')}
            />
            <InputOptionList
              mode={'radio'}
              name={'arePetsAllowed'}
              options={getTranslatedOptions(PETS_ALLOWED_SELECT_OPTIONS, t)}
              columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
              label={t('apartment.desiredTenant.arePetsAllowed')}
            />
            <InputOptionList
              mode={'radio'}
              name={'isHousingEntitlementCertificateMandatory'}
              options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
              columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
              label={t('isHousingEntitlementCertificateMandatory.label')}
            />{' '}
            <TextEditor
              hideToolbar={true}
              label={t('personalDescription.label')}
              placeholder={t('personalDescription.placeholder')}
              name={'personalDescription'}
              tooltipText={t('personalDescription.tooltip')}
              size={TEXT_EDITOR_SIZE.MEDIUM}
            />
          </Grid>
        </Grid>
        <FormNavigation
          flipButtons={true}
          buttonSubmitMode={buttonSubmitMode}
          disabled={false}
          showSecondaryButtonMobile={isTunnelView}
          formId={'apartmentDesiredTenantForm'}
          submitButtonText={isTunnelView ? t('saveAndContinue') : t('save')}
          secondaryButtonAction={() => navigate(-1)}
          secondaryButtonColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
          secondaryButtonText={t('back')}
        />{' '}
      </form>
    </FormContext.Provider>
  );
};

export default ApartmentDesiredTenantForm;
