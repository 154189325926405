import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  FIRESTORE_COLLECTION_PATH,
  ILandlordProfile,
  ITenantProfile,
  IUserWithRoles,
  USER_TYPE,
} from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from 'App';
import { User } from '@firebase/auth';

const useAuthUser = (): {
  user: IUserWithRoles;
  landlordProfile: ILandlordProfile;
  tenantProfile: ITenantProfile;
  activeUserType: USER_TYPE;
  isLoadingProfile: boolean;
  authUser: User;
  isPremiumUser: boolean;
} => {
  const { firebaseAuthService, firestoreService } = wohnsinnServices;
  const [user] = useAuthState(firebaseAuthService.auth);
  const [userData, setUserData] = useState<IUserWithRoles>(null);
  const [landlordProfile, setLandlordProfile] = useState<ILandlordProfile>(null);
  const [tenantProfile, setTenantProfile] = useState<ITenantProfile>(null);
  const [isLoadingProfile, setIsLoadingProfile] = useState<boolean>(true);
  const [isPremiumUser, setIsPremiumUser] = useState<boolean>(false);

  const handleUserLogout = (): void => {
    setUserData(null);
    setTenantProfile(null);
    setLandlordProfile(null);
    setIsLoadingProfile(false);
    setIsPremiumUser(false);
  };
  const getActiveOrTrialingSubscription = async () => {
    const db = getFirestore();
    const userUid = user.uid;
    const subscriptionsRef = collection(db, `users/${userUid}/subscriptions`);
    const q = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));
    onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        setIsPremiumUser(true);
      }
    });
  };

  useEffect(() => {
    onAuthStateChanged(firebaseAuthService.auth, (user) => {
      if (user) {
        const userDataRef = firestoreService.getDocRef(`${FIRESTORE_COLLECTION_PATH.users.root}/${user.uid}`);

        onSnapshot(userDataRef, (doc) => {
          setUserData(doc.data() as IUserWithRoles);
        });
      } else {
        handleUserLogout();
      }
    });
  }, [user]);

  useEffect(() => {
    if (userData?.uid) {
      if (userData.activeUserType === USER_TYPE.TENANT && userData?.isTenant) {
        const tenantProfileRef = firestoreService.getDocRef<ITenantProfile>(
          `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', userData.uid)}/${userData.uid}`
        );
        onSnapshot(tenantProfileRef, (doc) => {
          if (doc.exists()) {
            const tenantProfile: ITenantProfile = { ...doc.data() } as ITenantProfile;
            setTenantProfile(tenantProfile);
          }
        });
      }

      if (userData.activeUserType === USER_TYPE.LANDLORD && userData?.isLandlord) {
        const landlordProfileRef = firestoreService.getDocRef<ILandlordProfile>(
          `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', userData.uid)}/${userData.uid}`
        );

        onSnapshot(landlordProfileRef, (doc) => {
          if (doc.exists()) {
            const landlordProfile: ILandlordProfile = { ...doc.data() } as ILandlordProfile;
            setLandlordProfile(landlordProfile);
          }
        });
      }
      getActiveOrTrialingSubscription();
    }
    if (userData) {
      setIsLoadingProfile(false);
    }
  }, [userData]);

  return {
    user: userData ? userData : null,
    tenantProfile,
    landlordProfile,
    activeUserType: userData ? userData?.activeUserType : null,
    isLoadingProfile,
    authUser: firebaseAuthService.auth.currentUser,
    isPremiumUser: isPremiumUser,
  };
};

export default useAuthUser;
