import ModalWrapper from '../ModalWrapper';
import { FC, useContext } from 'react';
import ModalContext from 'core/context/modal.context';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'core/const/routes';
import CTAButton from 'component/atoms/Buttons/CTAButton';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import styles from './ShareApartmentLinksModal.module.scss';
import SnackBarContext from 'core/context/snackbar.context';
import { IMAGES } from 'core/const/images';

const text = 'Hey!\n\nIch habe auf Wohnsinn eine Wohnung gefunden, die dir gefallen könnte. Was sagst du dazu?\n\n';

const ShareApartmentLinkModal: FC = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { handleSnackBar } = useContext(SnackBarContext);

  const APARTMENT_LINK = `${process.env.REACT_APP_BASE_URL}${r(ROUTES.staticRoutes.shareApartment.path).replace(
    ':apartmentId',
    modalData?.apartmentId
  )}`;

  const copyLink = async () => {
    await navigator?.clipboard?.writeText(APARTMENT_LINK);
    handleSnackBar('toast.copyLink', 'info');
  };

  return (
    <ModalWrapper
      title={'Teile diese Wohnung'}
      buttons={{
        primary: {
          buttonText: t('close'),
          onClick: closeModal,
        },
      }}
    >
      <div className={styles.buttonWrapper}>
        <CTAButton onClick={copyLink} icon={faCopy} buttonText={'copy'} hideText />

        <a
          href={`https://api.whatsapp.com/send?text=${encodeURIComponent(text)}${APARTMENT_LINK}`}
          target={'_blank'}
          rel={'noreferrer noopener'}
        >
          <img
            style={{ width: 38, height: 38 }}
            src={IMAGES.logos.social.whatsapp.src}
            alt={IMAGES.logos.social.whatsapp.src}
          />
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${APARTMENT_LINK}`}
          target={'_blank'}
          rel={'noreferrer noopener'}
        >
          <img
            style={{ width: 38, height: 38 }}
            src={IMAGES.logos.social.facebook.src}
            alt={IMAGES.logos.social.facebook.src}
          />
        </a>
        <a
          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}${APARTMENT_LINK}`}
          target={'_blank'}
          rel={'noreferrer noopener'}
        >
          <img
            style={{ width: 38, height: 38 }}
            src={IMAGES.logos.social.xTwitter.src}
            alt={IMAGES.logos.social.xTwitter.src}
          />
        </a>
      </div>
    </ModalWrapper>
  );
};

export default ShareApartmentLinkModal;
