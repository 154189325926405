import styles from './ApartmentCard.module.scss';
import { IApartment, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import { FC, useContext } from 'react';
import VirtualTourStatusIndicator from '../../../atoms/VirtualTourStatusIndicator';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../../core/context/user.context';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../../../atoms/typographie/Text';
import Badge, { BADGE_COLORS } from '../../../atoms/Badge';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import TagList from '../../TagList';
import { TAG_COLOR } from '../../../atoms/Tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import ApartmentBadge from '../../../atoms/ApartmentBadge';
import ApartmentCardMedia from '../ApartmentCardMedia';
import { IApartmentWithRating } from '../../../../view/static/organization/OrganizationPortfolioView';

export enum APARTMENT_CARD_TYPE {
  APPLICATION = 'application',
  APARTMENT = 'apartment',
}

export interface IApartmentCardProps {
  apartment: IApartmentWithRating | IApartment;
  route?: string;
  notifications?: number;
  showLandlordInformations?: boolean;
  rating?: MATCHING_MODE;
  isAdmin?: boolean;
  showBadges?: boolean;
  onClick?: (apartmentId: string) => any;
}

const ApartmentCard: FC<IApartmentCardProps> = ({
  apartment,
  route,
  onClick,
  notifications,
  rating,
  showLandlordInformations,
  isAdmin,
  showBadges = true,
}) => {
  // hooks
  const { t } = useTranslation('common');
  // contexts
  const { user } = useContext(UserContext);
  // objects
  const { address } = apartment.mainInformation;
  const { ref, entry } = useInView({ trackVisibility: true, delay: 100, triggerOnce: true });

  const basics = [];
  if (apartment.cost?.warmRent) {
    basics.push({
      key: 'warmRent',
      label: `💰 ${apartment.cost.warmRent} €`,
    });
  } else if (apartment.cost?.coldRent) {
    basics.push({
      key: 'coldRent',
      label: `💰 ${apartment.cost.coldRent} €`,
    });
  }

  if (apartment?.areas?.numberOfRooms) {
    basics.push({
      key: 'rooms',
      label: `🛋️ ${apartment?.areas?.numberOfRooms} ${t('apartment.areas.numberOfRooms.label')}`,
    });
  }

  if (apartment?.areas?.totalArea) {
    basics.push({ key: 'size', label: `📐 ${apartment?.areas?.totalArea} m²` });
  }

  const Tag = onClick ? 'button' : 'div';

  return (
    <Tag onClick={onClick ? () => onClick(apartment.id) : undefined} ref={ref} className={styles.apartmentCard}>
      {!onClick && <Link to={route} className={styles.link} />}
      {showLandlordInformations || !showBadges ? null : (
        <ApartmentBadge
          apartmentIsExclusive={apartment?.isExclusive}
          isDisabled={!apartment?.isPublished && !apartment?.editorList?.includes(user?.uid)}
        />
      )}
      {notifications ? (
        <div className={styles.notificationsWrapper}>
          <div className={styles.notificationsBadge}>
            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_LIGHT} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
              {notifications.toString()}
            </Text>
          </div>
        </div>
      ) : null}

      {showLandlordInformations && apartment.editorList.includes(user?.uid) && apartment?.applicationRefList?.length ? (
        <div className={styles.applicationsCountWrapper}>
          <Badge
            color={BADGE_COLORS.PRIMARY}
            text={`${apartment?.applicationRefList?.length} ${
              apartment?.applicationRefList?.length === 1 ? t('application') : t('applications')
            }`}
          />
        </div>
      ) : null}

      {rating === 'NOPE' && <img alt={'nope'} src={t('icons.heart_nope')} className={styles.icon} />}
      {rating === 'MAYBE' && <img alt={'maybe'} src={t('icons.heart_like')} className={styles.icon} />}

      {apartment?.applicationRefList?.includes(user?.uid) && apartment?.applicationRefList?.length ? (
        <div className={styles.applicationsCountWrapper}>
          <Badge color={BADGE_COLORS.PRIMARY} text={`Bereits Beworben`} />
        </div>
      ) : null}

      <ApartmentCardMedia
        reelsView={false}
        media={apartment.media}
        apartmentId={apartment.id}
        isIntersecting={entry?.isIntersecting}
      />

      {showLandlordInformations ? (
        <div className={styles.statusBar}>
          <div className={`${styles.publishedState} ${apartment.isPublished ? styles.isPublished : ''}`}>
            <FontAwesomeIcon size={'sm'} icon={apartment.isPublished ? faCircleCheck : faCircleExclamation} />
            <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'}>
              {apartment.isPublished
                ? `${t('advertisement')} ${t('published')}`
                : `${t('advertisement')} ${t('unpublished')}`}
            </Text>
          </div>
          <VirtualTourStatusIndicator has3DTour={!!apartment?.matterportId || !!apartment?.virtualTourLink} />
        </div>
      ) : null}

      <div className={styles.content}>
        <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_DARK} weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
          {(address.hasPublishedAddress && address?.street) || showLandlordInformations
            ? `${address.street} ${address.houseNumber}`
            : 'Adresse auf Anfrage'}
        </Text>
        <br />
        <Headline tag={'span'} size={HEADLINE_SIZE.H3}>
          {address.postalCode} {address.city}
        </Headline>
        <div className={styles.bottom}>
          <TagList color={TAG_COLOR.BLUE} size={'small'} testId={'apartmentBasics'} tags={basics} />
        </div>

        {isAdmin ? (
          <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
            {user.uid !== apartment.creatorId ? (
              <>
                Creator ID: <br /> {apartment.creatorId}
              </>
            ) : null}
          </Text>
        ) : null}
      </div>
    </Tag>
  );
};

export default ApartmentCard;
