import styles from './ApartmentBadge.module.scss';
import Badge, { BADGE_COLORS } from '../Badge';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { FC } from 'react';
import { Timestamp } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

const ApartmentBadge: FC<{
  isDisabled?: boolean;
  apartmentIsExclusive?: boolean;
}> = ({ isDisabled = false, apartmentIsExclusive = false }) => {
  const { t } = useTranslation('common');

  if (isDisabled || apartmentIsExclusive) {
    return (
      <div className={styles.wrapper}>
        {isDisabled ? <Badge text={t('deactivated')} color={BADGE_COLORS.DISABLED} /> : null}
        {apartmentIsExclusive && <Badge text={`${t('✨ Nur bei Wohnsinn verfügbar')}`} color={BADGE_COLORS.DARK} />}
      </div>
    );
  }

  return null;
};

export const checkIfApartmentIsNew = (apartment: IApartment): boolean => {
  if (apartment.publishedAt) {
    const publishedDate = new Timestamp(
      // @ts-ignore
      apartment.publishedAt['_seconds'],
      // @ts-ignore
      apartment.publishedAt['_nanoseconds']
    ).toDate();
    const diff = (new Date().getTime() - publishedDate.getTime()) / 1000;
    const dayDiff = Math.floor(diff / 86400);
    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) return false;

    return dayDiff < 4;
  }
  return false;
};

export default ApartmentBadge;
