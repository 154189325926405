import React, { useContext } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import styles from './InputSelect.module.scss';
import { FormContext } from '../../../../core/context/form.context';
import { ISelectOption } from '@wohnsinn/ws-ts-lib';
import FormErrorMessage from '../FormErrorMessage';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT, TEXT_WEIGHT } from '../../typographie/Text';
import { InputLabel } from '@mui/material';

export interface IInputOptionListProps<T> {
  mode: 'checkbox' | 'radio';
  name: string;
  label?: string;
  options: ISelectOption<T>[];
  required?: boolean;
  columns?: RADIO_INPUT_COLUMNS_COUNT;
  subLabel?: string;
  helperText?: string;
  hideErrorMessage?: boolean;
  centerLabel?: boolean;
  singleSelect?: boolean;
  children?: React.ReactNode;
}

export enum RADIO_INPUT_COLUMNS_COUNT {
  TWO = 'TWO',
  THREE = 'THREE',
  FIVE = 'FIVE',
}

const InputOptionList = <T,>({
  mode,
  name,
  label,
  options,
  required,
  columns = RADIO_INPUT_COLUMNS_COUNT.TWO,
  subLabel,
  singleSelect = true,
  helperText,
  hideErrorMessage = false,
  centerLabel = false,
  children,
}: IInputOptionListProps<T>) => {
  const { control } = useContext(FormContext);

  const changeHandler = (field: ControllerRenderProps, value: any): void => {
    if (mode === 'radio') {
      if (singleSelect && field.value == value) {
        field.onChange(undefined);
        return;
      }
      field.onChange(value);
      return;
    } else {
      if (!field.value) {
        field.onChange([value]);
      } else {
        if (field.value.findIndex((v: string) => v === value) === -1) {
          field.onChange([...field.value, value]);
        } else {
          field.onChange(field.value.filter((v: string) => v !== value));
        }
      }
    }
  };

  return control ? (
    <Controller
      name={name}
      rules={{ required }}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className={'form-field'}>
          {label && <InputLabel required={required}>{label}</InputLabel>}

          <fieldset className={styles.radioButtonWrapper}>
            <legend className={'sr-only'}>{name}</legend>
            <div className={`${styles.options} ${styles[columns]}`}>
              {options.length &&
                options.map((option) => (
                  <label
                    htmlFor={name}
                    key={`${name}-${option.value?.toString()}`}
                    className={columns ? styles[columns] : ''}
                  >
                    <input
                      onChange={(e) => field?.onChange(e)}
                      onBlur={field?.onBlur}
                      className={'styled-checkbox'}
                      type={mode}
                      id={`${name}-${option.value}`}
                    />
                    <button
                      type={'button'}
                      className={`${styles.radio} ${
                        (mode === 'radio' && field?.value === option.value) ||
                        (mode === 'checkbox' && field.value?.includes(option.value))
                          ? styles.active
                          : ''
                      }`}
                      aria-hidden="true"
                      onClick={() => changeHandler(field, option.value)}
                    >
                      <Text
                        weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
                        color={
                          (mode === 'radio' && field?.value === option.value) ||
                          (mode === 'checkbox' && field.value?.includes(option.value))
                            ? TEXT_COLOR.TEXT_COLOR_LIGHT
                            : TEXT_COLOR.TEXT_COLOR_DARK
                        }
                      >
                        {option.label}
                      </Text>
                    </button>
                    <span className="sr-only">{option.label}</span>
                    {subLabel && <span className="sub-label">{subLabel}</span>}
                  </label>
                ))}
            </div>
          </fieldset>
          {children}
          {!hideErrorMessage && <FormErrorMessage error={!field?.value ? error : null} />}
          {helperText ? (
            <div className={styles.helperText}>
              <Text
                align={centerLabel ? 'center' : 'left'}
                type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
              >
                {helperText}
              </Text>
            </div>
          ) : null}
        </div>
      )}
    />
  ) : (
    <> </>
  );
};

export default InputOptionList;
