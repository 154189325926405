import DocumentUploadDropZone, { UPLOAD_TYPE } from 'component/molecules/DocumentUploadDropZone';
import UserContext from 'core/context/user.context';
import useTenantDocuments from 'core/hook/tenant-document.hook';
import { FC, useContext, useEffect, useState } from 'react';
import { IUpload } from '@wohnsinn/ws-ts-lib';
import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ITenantFormProps } from '../TenantProfileForm';
import PageTitle from 'component/molecules/PageTitle';
import { ROUTES } from 'core/const/routes';
import styles from '../TenantIntroductionVideoForm/TenantIntroductionVideoForm.module.scss';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import DocumentUploadFileList from 'component/molecules/DocumentUploadDropZone/DocumentUploadFileList';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import InfoBox, { INFO_BOX_ICONS } from 'component/atoms/InfoBox';
import { Grid } from '@mui/material';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import InfoText from 'component/molecules/InfoText';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { useNavigate } from 'react-router-dom';

const TenantIncomeProofForm: FC<ITenantFormProps> = ({ isApplicationFolderTunnel }) => {
  const [incomeProofDocumentUploadList, setIncomeProofDocumentUploadList] = useState<IUpload[]>([]);
  const { tenantProfile } = useContext(UserContext);
  const { incomeProofDocumentList } = useTenantDocuments(tenantProfile?.uid);
  const navigate = useNavigate();
  const redirectUrl = localStorage.getItem(LOCAL_STORAGE_KEYS.REDIRECT_URL);
  const { t: c } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common', { keyPrefix: 'view.AccountView.IncomeProofView' });
  const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';

  useEffect(() => {
    setIncomeProofDocumentUploadList(incomeProofDocumentList);
  }, [incomeProofDocumentList]);

  const updateUploadStateHandler = (uploadList: IUpload[]): void => {
    let uniqueUpload: IUpload[] = [];
    uniqueUpload = uniqBy<IUpload>([...incomeProofDocumentUploadList, ...uploadList], 'fileName');
    setIncomeProofDocumentUploadList(uniqueUpload);
  };

  const documentDeleteHandler = (upload: IUpload): void => {
    setIncomeProofDocumentUploadList(
      uniqBy<IUpload>(
        incomeProofDocumentUploadList.filter((u) => u.id !== upload.id),
        'fileName'
      )
    );
  };

  const handleRoute = () => {
    const apartmentId = localStorage.getItem(LOCAL_STORAGE_KEYS.APARTMENT_ID);
    if (!!apartmentId && isApplicationFolderTunnel) {
      return navigate(r(ROUTES.staticRoutes.shareApartment.path).replace(':apartmentId', apartmentId));
    }
    if (redirectUrl) {
      navigate(redirectUrl);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.REDIRECT_URL);
      return;
    } else {
      return navigate('/');
    }
  };

  const CANCEL_BUTTON = {
    text: isApplicationFolderTunnel ? c('skip') : c('cancel'),
    action: () => (!isApplicationFolderTunnel ? navigate(r(ROUTES.tenantRoutes.account.overview.path)) : handleRoute()),
  };

  return (
    <Grid container>
      <Grid item>
        <div className={styles.pageTitleActionWrapper}>
          <PageTitle
            pageTitle={r(ROUTES.tenantRoutes.account.incomeProof.title)}
            secondPageTitle={'Lade Dokumente hoch, die deine Zahlungsfähigkeit nachweisen.'}
            notSubmitButton={
              !isWogeEnvironment && isApplicationFolderTunnel && !tenantProfile?.hasIncomeProofDocs
                ? CANCEL_BUTTON
                : null
            }
          />
          <div className={styles.videoUpload}>
            {tenantProfile?.hasIncomeProofDocs && isApplicationFolderTunnel ? (
              <CTAButton
                customStyling={styles.hideMobile}
                buttonStyle={BUTTON_STYLE.PRIMARY}
                spinIcon
                type={'submit'}
                onClick={async () => {
                  handleRoute();
                }}
                buttonText={c('finish')}
              />
            ) : (
              <DocumentUploadDropZone
                uploadType={UPLOAD_TYPE.INCOME_PROOF}
                onUploadListCreation={updateUploadStateHandler}
              />
            )}
          </div>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7} lg={8}>
            <Headline size={HEADLINE_SIZE.H3}>{t('upload.title')}</Headline>
            <DocumentUploadFileList
              uploadType={UPLOAD_TYPE.INCOME_PROOF}
              documentUploadList={incomeProofDocumentUploadList}
              onDocumentDelete={documentDeleteHandler}
            />
            <InfoBox icon={INFO_BOX_ICONS.SHIELD} text={t('infoBox')} />
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <InfoText>
              <Headline size={HEADLINE_SIZE.H2}>Erhöhe deine Chancen</Headline>{' '}
              <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                Das Hochladen von Einkommensnachweisen wird oft von Vermietern erwartet und ist daher empfehlenswert, da
                es die Wahrscheinlichkeit einer erfolgreichen Bewerbung erhöht und dem Vermieter die notwendigen
                Informationen für eine fundierte Entscheidung liefert.
              </Text>
            </InfoText>
          </Grid>
        </Grid>

        <Grid item xs={12} className={styles.mediaUploadWrapper}>
          {!isWogeEnvironment && isApplicationFolderTunnel && !tenantProfile.hasIncomeProofDocs ? (
            <CTAButton
              type={'button'}
              expandMobile
              onClick={CANCEL_BUTTON.action}
              buttonStyle={BUTTON_STYLE.SECONDARY}
              buttonText={CANCEL_BUTTON.text}
              mobileNotRounded
            />
          ) : null}

          {tenantProfile?.hasIncomeProofDocs ? (
            <CTAButton
              buttonStyle={BUTTON_STYLE.PRIMARY}
              spinIcon
              type={'submit'}
              onClick={async () => {
                handleRoute();
              }}
              buttonText={c('finish')}
              mobileNotRounded
            />
          ) : (
            <DocumentUploadDropZone
              uploadType={UPLOAD_TYPE.INCOME_PROOF}
              onUploadListCreation={updateUploadStateHandler}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TenantIncomeProofForm;
