import { TableCell, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Text from 'component/atoms/typographie/Text';
import { FC } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import styles from 'component/atoms/TableCellLink/TableCellLink.module.scss';

const TableCellLink: FC<{
  noWrap?: boolean;
  toLink?: string; // Marked optional since onClick might be used instead
  info?: string | number;
  showToolTip?: boolean;
  children?: ReactJSXElement;
  showInfo?: boolean;
  infoSecondLine?: string;
  onClick?: () => void; // New onClick prop
}> = ({ toLink, info, showToolTip = false, children, showInfo = true, noWrap = true, infoSecondLine, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (toLink) {
      navigate(toLink);
    }
  };

  return (
    <TableCell className={styles.hover} onClick={handleClick}>
      {showToolTip ? (
        <Tooltip title={info ?? ''}>
          <Text noWrap={noWrap}>{info ?? '-'}</Text>
        </Tooltip>
      ) : (
        <>
          {showInfo && <Text noWrap={noWrap}>{info ?? '-'}</Text>}
          {showInfo && infoSecondLine && <Text noWrap={noWrap}>{infoSecondLine ?? '-'}</Text>}
        </>
      )}
      {children}
    </TableCell>
  );
};

export default TableCellLink;
