import React from 'react';
import styles from './PricingOptions.module.scss';
import { useTranslation } from 'react-i18next';
import { PriceOption } from 'component/atoms/PremiumSubscriptionDrawer';

interface PricingOptionsProps {
  priceList: PriceOption[];
  selectedOption: PriceOption;
  setSelectedOption: (option: PriceOption) => void;
}

const PricingOptions: React.FC<PricingOptionsProps> = ({ priceList, selectedOption, setSelectedOption }) => {
  const { t } = useTranslation('common', { keyPrefix: 'stripe' });

  const handleSelect = (price: PriceOption) => {
    setSelectedOption(price);
  };

  const getWeekPrice = (price: PriceOption) => {
    if (price?.interval === 'week') {
      return (price.unit_amount / 100).toFixed(2);
    }
    if (price?.interval === 'month') {
      return (price.unit_amount / (price?.interval_count * 400)).toFixed(2);
    }
  };

  return (
    <div className={styles.pricingWrapper}>
      {priceList.map((price, index) => (
        <div
          key={index}
          className={`${styles.priceOption}  ${selectedOption?.id === price.id ? styles.selected : ''}`}
          onClick={() => handleSelect(price)}
        >
          <input
            type="radio"
            name="pricing"
            value={price.id}
            checked={selectedOption?.id === price.id}
            onChange={() => handleSelect(price)}
            style={{ display: 'none' }}
          />
          <div className={styles.priceWrapper}>
            <div className={styles.firstRow}>
              <h3 className={styles.priceTitle}>
                {price.interval_count} {t(price?.interval, { count: price?.interval_count })}
              </h3>
              {price?.metadata?.default && <div className={styles.popularBadge}>🩵 Beliebt</div>}
            </div>
            <div className={styles.firstRow}>
              <div className={styles.priceValue}>
                <p>{getWeekPrice(price)} € pro Woche</p>
              </div>
              {price?.metadata?.saving && (
                <div className={styles.savingBadge}> {`Spare ${price.metadata.saving}%`} </div>
              )}{' '}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricingOptions;
