import ApplicationListContext from 'core/context/application-list.context';
import BackButton from 'component/atoms/Buttons/BackButton';
import BurgerMenu from './BurgerMenu';
import CTAButton, { BUTTON_STYLE } from '../../atoms/Buttons/CTAButton';
import DesktopMenu from './DesktopMenu';
import MegaMenu from 'component/molecules/MegaMenu';
import ModalContext from 'core/context/modal.context';
import UserContext from 'core/context/user.context';
import styles from './AppHeader.module.scss';
import useLandlordApartmentListHook from 'core/hook/landlord-apartment-list.hook';
import useWindowSize from 'core/hook/windowsize.hook';
import { FC, useContext } from 'react';
import { IMenuNotifications } from 'core/const/menus';
import { Link, useLocation } from 'react-router-dom';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { REGISTRATION_REFERRER, REGISTRATION_STATES, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { ROUTES } from 'core/const/routes';
import { getAdminMenuList, getGuestMenuList, getLandlordMenuList, getTenantMenuList } from 'core/helper/get-menu';
import { useTranslation } from 'react-i18next';

export interface IAppHeader {
  backButtonRoute?: string;
  headerEndButton?: any;
  headerStartButton?: any;
  pageTitle?: string;
  hideMenu?: boolean;
  showBackButton?: boolean;
  showStaticMenu?: boolean;
  tunnelMode?: boolean;
  referrer?: REGISTRATION_REFERRER;
}

const AppHeader: FC<IAppHeader> = ({
  headerStartButton = null,
  headerEndButton,
  backButtonRoute,
  showBackButton = false,
  hideMenu = false,
  showStaticMenu = false,
  referrer,
  tunnelMode = false,
}) => {
  const { isGreaterMd, isSmallerLg } = useWindowSize();
  const { openModal } = useContext(ModalContext);
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { unreadNotifications: unreadTenantChatNotifications, maybeList } = useContext(ApplicationListContext);
  const { unreadTenantChatsCount } = useLandlordApartmentListHook();
  const { user, activeUserType, isPremiumUser } = useContext(UserContext);
  const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';
  const location = useLocation();
  const registrationPath = referrer
    ? r(ROUTES.staticRoutes.registration.createRegistration.referrer).replace(':referrer', referrer)
    : r(ROUTES.staticRoutes.registration.createRegistration.path);

  const createMenu = (notifications: IMenuNotifications) => {
    const menu = user
      ? activeUserType === USER_TYPE.LANDLORD
        ? getLandlordMenuList(notifications)
        : getTenantMenuList(notifications)
      : getGuestMenuList();
    if (user && user.isAdmin) {
      menu.push(getAdminMenuList());
    }

    return menu;
  };

  const MENU = createMenu({
    account: !user?.isEmailVerified,
    landlordChat: unreadTenantChatsCount,
    tenantChat: unreadTenantChatNotifications,
    bookmark: maybeList?.length ? maybeList.length : 0,
  });

  const renderLogo = () => (
    <div
      className={`${styles.appLogo} ${isWogeEnvironment ? styles.wogeLogo : ''} ${
        isWogeEnvironment ? 'woge-logo' : isPremiumUser ? 'app-logo-pro' : 'app-logo'
      }`}
    />
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        {showBackButton ? <BackButton backRoute={backButtonRoute} /> : null}
        {tunnelMode ? (
          renderLogo()
        ) : location.pathname === '/' ? (
          <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>{renderLogo()}</button>
        ) : (
          <Link to={'/'}>{renderLogo()}</Link>
        )}

        {!hideMenu && showStaticMenu ? (
          <div className={styles.megaMenu}>
            <MegaMenu />
          </div>
        ) : null}
      </div>

      <>
        {!hideMenu &&
        isGreaterMd &&
        MENU &&
        MENU.length &&
        activeUserType === USER_TYPE.LANDLORD &&
        user?.registrationState === REGISTRATION_STATES.FINISHED ? (
          <DesktopMenu menuItemList={MENU} />
        ) : null}
        <div className={styles.accountSection}>
          {headerEndButton ? headerEndButton : null}
          {!user ? (
            <div className={styles.buttonWrapper}>
              <CTAButton
                buttonStyle={BUTTON_STYLE.SECONDARY}
                expand={'block'}
                buttonText={t('signIn')}
                onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })}
              />
              {tunnelMode ? null : <CTAButton expand={'block'} buttonText={t('signUp')} link={registrationPath} />}
            </div>
          ) : null}

          {!hideMenu && MENU ? <BurgerMenu menu={MENU} /> : null}
        </div>
      </>
    </div>
  );
};

export default AppHeader;
