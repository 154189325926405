import { FC, useContext } from 'react';
import { Controller } from 'react-hook-form';
import styles from './text-editor.module.scss';
import FormErrorMessage from '../FormErrorMessage';
import { FormContext } from '../../../../core/context/form.context';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../typographie/Text';
import QuillEditor from '../QuillEditor';
import { InputLabel, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

export enum TEXT_EDITOR_SIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export interface IEditorProps {
  helperText?: string;
  label?: string;
  maxLength?: number;
  name: string;
  placeholder?: string;
  required?: boolean;
  hideToolbar?: boolean;
  size?: TEXT_EDITOR_SIZE;
  tooltipText?: string;
}

const TextEditor: FC<IEditorProps> = (props) => {
  const {
    helperText,
    label,
    name,
    placeholder,
    required,
    size = TEXT_EDITOR_SIZE.SMALL,
    tooltipText,
    hideToolbar = false,
  } = props;
  const { control } = useContext(FormContext);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <div className={styles.formField}>
          {label ? (
            <InputLabel>
              {label} {label && required && <span> *</span>}
              {tooltipText && (
                <Tooltip title={tooltipText} classes={{ tooltip: styles.tooltip }}>
                  <FontAwesomeIcon icon={faInfoCircle} size={'1x'} />
                </Tooltip>
              )}
            </InputLabel>
          ) : null}
          <QuillEditor hideToolbar={hideToolbar} size={size} placeholder={placeholder} field={field} />

          {helperText && (
            <div>
              <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                {helperText}
              </Text>
            </div>
          )}
          <div className={styles.errorWrapper}>
            <span className={styles.errorMessageWrapper}>
              <FormErrorMessage error={error} />
            </span>
          </div>
        </div>
      )}
    />
  );
};

export default TextEditor;
