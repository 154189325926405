import { useContext, useEffect, useState } from 'react';
import { IApartment, IWhereFilterArguments, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { onSnapshot, Unsubscribe } from 'firebase/firestore';
import UserContext from '../context/user.context';
import { wohnsinnServices } from 'App';

function useLandlordApartmentListHook(whereFilterArguments?: IWhereFilterArguments): {
  apartmentList: IApartment[];
  isLoading: boolean;
  unreadTenantChatsCount: number;
} {
  const { user } = useContext(UserContext);
  const { apartmentService } = wohnsinnServices;
  const [apartmentList, setApartmentList] = useState<IApartment[]>([]);
  const [unreadTenantChatsCount, setUnreadTenantChatsCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setIsLoading(true);
    if (user && user?.activeUserType === USER_TYPE.LANDLORD) {
      const apartmentQuery = apartmentService.getLandlordApartmentListRef({
        uid: user.uid,
        limit: null,
        whereFilterArguments,
      });
      (async () => {
        unsubscribe = onSnapshot(
          apartmentQuery,
          (apartmentSnap) => {
            let unreadChatsCount = 0;
            const apartmentList = apartmentSnap.docs.map((doc) => {
              const apartment = doc.data();
              if (apartment.unreadTenantChatsRef?.length) {
                unreadChatsCount++;
              }
              return apartment;
            });

            setUnreadTenantChatsCount(unreadChatsCount);
            setApartmentList(apartmentList);
            setIsLoading(false);
          },
          (error) => console.error(error)
        );
        if (apartmentList.length) {
        } else {
          setApartmentList([]);
        }
      })();
    }
    return unsubscribe;
  }, []);

  return { apartmentList, isLoading, unreadTenantChatsCount };
}

export default useLandlordApartmentListHook;
