import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chat from '../index';
import ThreeColumnsLayoutMiddle from '../../ThreeColumnsLayout/ChatLayoutMiddle';
import ThreeColumnsLayoutLeftSide from '../../ThreeColumnsLayout/ThreeColumnsLayoutLeftSide';
import ChatListItem from '../../../molecules/ChatListItem';
import { IApplication, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import ThreeColumnsLayoutContext from '../../../../core/context/three-columns-layout-context';
import ApartmentInformation from '../../ApartmentInformation';
import ThreeColumnsLayoutRightSide from '../../ThreeColumnsLayout/ThreeColumnsLayoutRightSide';
import ApplicationListContext from '../../../../core/context/application-list.context';
import useWindowSize from '../../../../core/hook/windowsize.hook';
import { ROUTES } from '../../../../core/const/routes';
import CTACard from '../../../molecules/Cards/CTACard';
import styles from '../Chat.module.scss';
import useApartment from '../../../../core/hook/apartment.hook';
import { wohnsinnServices } from '../../../../App';
import { Grid } from '@mui/material';
import PageTitle from '../../../molecules/PageTitle';

const TenantChatWrapper: FC = () => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { applicationService, mixpanelTrackingService } = wohnsinnServices;
  const { setPageTitle, showMiddle, showLeft } = useContext(ThreeColumnsLayoutContext);
  const [selectedApplication, setSelectedApplication] = useState<IApplication>(null);
  const { apartment } = useApartment(selectedApplication?.apartmentId);
  const { likeList: applicationList } = useContext(ApplicationListContext);
  const { isSmallerMd } = useWindowSize();

  const handleApplicationSelection = (application: IApplication, isInitial: boolean): Promise<void> => {
    mixpanelTrackingService.trackEvent('OpenedChatMessage', { apartment: application.apartmentId });
    setSelectedApplication(application);

    if (!isInitial) {
      showMiddle();
    } else {
      showLeft();
    }

    if (isInitial && isSmallerMd) {
      setPageTitle(r(ROUTES.tenantRoutes.chat.title));
    } else {
      setPageTitle(
        `${
          application.address.hasPublishedAddress
            ? application.address.street
            : `${application.address.postalCode} ${application.address.city}`
        }`
      );
    }

    return applicationService.markUnread(application, USER_TYPE.TENANT);
  };

  useEffect(() => {
    if (!selectedApplication && applicationList.length) {
      handleApplicationSelection(applicationList[0], true);
    }

    if (selectedApplication && applicationList.length) {
      applicationService.markUnread(selectedApplication, USER_TYPE.TENANT);
    }
  }, [applicationList]);

  if (!applicationList.length) {
    return (
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={6}>
          <div className={styles.emptyApplicationsWrapper}>
            <CTACard
              title={t('noApplications.title')}
              text={t('noApplications.text')}
              imgSrc={t('pictogram.chatMessage')}
              imgAltText={t('bookmarkIcon')}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <ThreeColumnsLayoutLeftSide>
        {isSmallerMd ? (
          <Grid className={styles.pageTitle} container paddingX={2} justifyContent="center">
            <Grid item xs={12} md={10} xl={7}>
              <PageTitle pageTitle={'Nachrichten'} />
            </Grid>
          </Grid>
        ) : null}
        <ul className={styles.list}>
          {applicationList.map((a) => (
            <ChatListItem
              key={a.apartmentId}
              isActive={a.apartmentId === selectedApplication?.apartmentId}
              itemClickHandler={(application) => handleApplicationSelection(application, false)}
              application={a}
              hasUnreadMessages={!!a.unreadLandlordMessagesRef?.length}
            />
          ))}
        </ul>
      </ThreeColumnsLayoutLeftSide>

      <ThreeColumnsLayoutMiddle>
        {selectedApplication && <Chat key={selectedApplication.apartmentId} application={selectedApplication} />}
      </ThreeColumnsLayoutMiddle>

      <ThreeColumnsLayoutRightSide>
        {!!apartment && <ApartmentInformation key={`${apartment.id}`} apartment={apartment} />}
      </ThreeColumnsLayoutRightSide>
    </>
  );
};

export default TenantChatWrapper;
